import { Injectable } from '@angular/core';
import { AppStoreService } from '../store/app-store.service';
import { Router } from '@angular/router';
import { unixTimeStamp } from '../utils/date-time';
import { SessionService } from './session.service';
import { SessionKey } from '../enums/session-key.enum';
import { Token } from '../models/token.model';
// import { AuthenticationApiService } from './api/authentication/authentication-api.service';
import { UnsubscribeOnDestroyAdapter } from '../../shared';
// import { HttpErrorResponse } from '@angular/common/http';
// import { ErrorHandlerService } from './error-handler.service';
import { MessageService } from './message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends UnsubscribeOnDestroyAdapter {
  constructor(
    private readonly _appStoreService: AppStoreService,
    private readonly _router: Router,
    private readonly _sessionService: SessionService,
    // private readonly _authenticationApiService: AuthenticationApiService,
    // private readonly _errorHandlerService: ErrorHandlerService,
    private readonly _messageService: MessageService,
  ) {
    super();
  }

  get isAuthenticated(): boolean {
    if (
      this._appStoreService.token != null &&
      this._appStoreService.token.exp > unixTimeStamp()
    ) {
      return true;
    } else {
      if (
        this._appStoreService.token != null &&
        !(this._appStoreService.token.exp > unixTimeStamp())
      ) {
        this.logout('Lejárt a munkamenete. Jelentkezzen be újra!'); // TOKEN_EXPIRED
      }
    }
    return false;
  }

  get token(): Token | null {
    return this._appStoreService.token;
  }

  get tokenUserName(): string {
    return this._appStoreService.tokenUserName;
  }

  logout(logoutMessage?: string, userLogout = false): void {
    // this._subscription.sink = this._authenticationApiService.logout().subscribe({
    //   next: () => {
    //     if(logoutMessage){
    //       if(userLogout){
    //         this._messageService.addSuccessMessage(logoutMessage, 'logout-action', true);
    //       }
    //       else{
    //         this._messageService.addDangerMessage(logoutMessage, 'logout-action', true);
    //       }
    //     }
    //   },
    //   error: (error: HttpErrorResponse) => {
    //     this._errorHandlerService.handleError(error, 'logout-action');
    //   }
    // });

    // no POST /logout
    if(logoutMessage){
      if(userLogout){
        this._messageService.addSuccessMessage(logoutMessage, 'logout-action', true);
      }
      else{
        this._messageService.addDangerMessage(logoutMessage, 'logout-action', true);
      }
    }
    // no POST /logout

    this.logoutActions();
  }

  private logoutActions(): void {
    this.logoutPurge();
    this._router.navigate(['/login']);
  }

  logoutPurge(): void {
    this._appStoreService.clearAll();
    this._sessionService.removeItem(SessionKey.TOKEN);
  }
}
